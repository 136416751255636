<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-pencil" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Edit SHS Course
                    </div>
                    <div class="text-sm text-500">Modify SHS course record</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              SHS Course Name *
                            </div>
                            <div id="ctrl-shs_course_name-holder">
                              <InputText ref="ctrlshs_course_name" v-model.trim="formData.shs_course_name"
                                label="SHS Course" type="text" placeholder="Enter SHS Course Name" class="w-full"
                                :class="getErrorClass('shs_course_name')">
                              </InputText>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="mb-2 font-bold text-sm">
                              SHS Course Description
                            </div>
                            <div id="ctrl-shs_course_description-holder">
                              <Textarea :class="getErrorClass('shs_course_description')" class="w-full"
                                ref="ctrlshs_course_description" rows="2" v-model="formData.shs_course_description"
                                placeholder=" SHS Course Description" type="textarea">
                              </Textarea>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  decimal,
  email,
  integer,
  ipAddress,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { EditPageMixin } from "../../mixins/editpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editCapsshscoursesPage",
  components: {},
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_shs_courses",
    },
    idName: {
      type: String,
      default: "caps_shs_courses_id",
    },
    routeName: {
      type: String,
      default: "caps_shs_coursesedit",
    },
    pagePath: {
      type: String,
      default: "caps_shs_courses/edit",
    },
    apiPath: {
      type: String,
      default: "caps_shs_courses/edit",
    },
  },
  data() {
    return {
      formData: {
        shs_course_name: "",
        shs_course_description: "",
      },
      submitted: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Programme";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_shs_courses/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("caps_shs_courses/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      shs_course_name: { required },
      shs_course_description: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("caps_shs_courses", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          // if (this.redirect) this.navigateTo(`/caps_shs_courses`);
          this.$router.push(this.$route.path);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        shs_course_name: "",
        shs_course_description: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>
